
import { Component, ProvideReactive } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import { RouteMixin } from '@/modules/common/mixins/route';
import {
  ActionableHelperView,
  NavBar,
  HelperView,
  ContextColorProvider
} from '@warrenbrasil/nebraska-web';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { percentageWithSign } from '@/modules/common/helpers/percentage';
import { WatchListButton } from '@/modules/trade/components/WatchListButton';
import { TwoColumnsContainerLoader } from '@/modules/common/components/TwoColumnsContainer';
import { Notification } from '@/modules/common/components/Notification';
import { FirstAccessUnifiedAccountModal } from '@/modules/trade/components/FirstAccessUnifiedAccountModal';

import {
  IInstrumentAbout,
  InstrumentPriceMessage,
  getInstrumentDetailView,
  getInstrumentPosition,
  getInstrumentPrice,
  getInstrumentStatus,
  InstrumentStatus
} from '@/modules/trade/services/instruments';

import { TradeInstrumentPositionPrice } from '@/modules/trade/types/instrument';
import { FeatureFlags } from '@/types/models/FeatureFlags';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';

import { InstrumentContent } from './components/InstrumentContent';

import { SignalR, IInstrumentStatus } from '@/modules/trade/services/signalr';
import { getBalances, AccountStatus } from '@/modules/trade/services/account';
import { NumberFormatted, StringFormatted } from '@/modules/trade/types';
import { trackInstrumentViewLoad, trackWatchlistButtonClick } from './trackers';
import {
  checkUserBrokerAccount,
  enableUserBrokerAccount
} from '@/modules/trade/services/unified-account';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import { logError } from '@/modules/trade/helpers/logs';

const tradePositionsModule = namespace('tradeModule');

@Component({
  filters: { percentageWithSign },
  components: {
    ActionableHelperView,
    NavBar,
    BackButton,
    HelperView,
    TwoColumnsContainerLoader,
    WatchListButton,
    Notification,
    InstrumentContent,
    ContextColorProvider,
    FirstAccessUnifiedAccountModal
  }
})
export default class TradeInstrumentView extends mixins(RouteMixin) {
  @tradePositionsModule.Action('changeUserHasBrokerAccountValue')
  private changeUserHasBrokerAccountValue!: Function;

  @tradePositionsModule.Action('fetchStatus')
  private fetchStatus!: Function;

  @tradePositionsModule.State('tradeStatus')
  readonly tradeStatus!: AccountStatus;

  private bestSellPrice = 0;
  private bestBuyPrice = 0;
  private price: NumberFormatted = {
    value: 0,
    formatted: '-'
  };

  private position: TradeInstrumentPositionPrice | null = null;

  private balanceAvailable: NumberFormatted | null = null;
  private loading = true;
  private error = false;
  private latestPriceDate: StringFormatted | null = null;
  private isOnWatchlist!: boolean;
  private canOperate = false;
  private instrumentMessage: InstrumentPriceMessage | null = null;
  private instrumentAbout!: IInstrumentAbout | Object;
  private instrumentStatus: IInstrumentStatus | Object = {};
  instrument: InstrumentStatus | null = null;
  description = '';
  isOpenModalOpenBrokerAccount = false;

  // O usuário consegue digitar um ticker minúsculo direto pela URL, então estamos convertendo o ticker
  // para ser todo maiúsculo, para prevenir errors com serviços utilizados(api) na view que são case sensitive
  @ProvideReactive('ticker')
  ticker = '';

  mounted() {
    this.ticker = this.$route.params.ticker.toLocaleUpperCase();
    this.load();
  }

  get isTradeNewBrokerFeatureFlagEnabled() {
    return FeatureFlagsModule.isEnabled(
      FeatureFlags.TRADE_ENABLE_NEW_BROKER_CLIENT_FLOW
    );
  }

  get isWatchlistButtonEnabled() {
    return !this.error && !this.loading;
  }

  private async load() {
    this.loading = true;
    this.error = false;

    await Promise.all([
      this.socketConnect(),
      this.fetchStatus(),
      this.fetchInstrumentStatus(),
      this.fetchBalance(),
      this.fetchPositions(),
      this.fetchFirstAccessUnifiedAccount(),
      this.fetchPrice(),
      this.fetchInstrumentDetailView()
    ]);
    this.loading = false;
    if (!this.error) {
      trackInstrumentViewLoad(
        this.instrument!.symbol,
        this.description,
        this.instrument!.type.formatted
      );
    }
  }

  private async socketConnect() {
    try {
      await SignalR.startConnection();
      await SignalR.invokeWithSubscription('JoinGroup', [this.ticker]);
    } catch (error) {
      console.log(error);
    }
  }

  async destroyed() {
    await SignalR.stopConnection();
  }

  private async fetchPrice() {
    try {
      const data = await getInstrumentPrice(this.ticker);

      this.bestSellPrice = data.bestOfferSell?.value || 0;
      this.bestBuyPrice = data.bestOfferBuy?.value || 0;
      this.price = data.price;
      this.latestPriceDate = {
        value: data.date,
        formatted: data.updatedAt?.formatted || '-'
      };
      this.instrumentMessage = data.message;
      this.isOnWatchlist = data.favorited;

      SignalR.connection.on('MarketData', data => {
        if (data.ticker !== this.ticker) return;

        this.latestPriceDate = data.date;
        this.instrumentStatus = data.status;
        this.price = data.price;
        this.bestBuyPrice = data.bestPriceBuy.value;
        this.bestSellPrice = data.bestPriceSell.value;

        this.setPositionsTotalEarnings(this.price);
      });
    } catch (error) {
      this.error = true;
    }
  }

  private async fetchPositions() {
    try {
      const position = await getInstrumentPosition(this.ticker);
      this.position = position;
    } catch (error) {
      this.error = true;
    }
  }

  private async fetchBalance() {
    try {
      const data = await getBalances();

      this.balanceAvailable = data.availableBalanceToInvest;
    } catch (error) {
      this.error = true;
    }
  }

  private async fetchFirstAccessUnifiedAccount() {
    try {
      if (this.isTradeNewBrokerFeatureFlagEnabled) {
        const userHasBrokerAccount = await checkUserBrokerAccount();

        this.isOpenModalOpenBrokerAccount = !userHasBrokerAccount;
        this.changeUserHasBrokerAccountValue(userHasBrokerAccount);
      }
    } catch (error) {
      logError(error, 'request_has_broker_account_instrument_error');
      this.error = true;
    }
  }

  openFirstAccessUnifiedAccountModal() {
    this.isOpenModalOpenBrokerAccount = true;
  }

  closeFirstAccessUnifiedAccountModal() {
    this.isOpenModalOpenBrokerAccount = false;
  }

  async enableMarketClient() {
    try {
      this.closeFirstAccessUnifiedAccountModal();
      await enableUserBrokerAccount();
      this.$notify({
        group: 'wrn-trade',
        text: 'Conta habilitada com sucesso.',
        type: NotificationTypes.success
      });
      this.changeUserHasBrokerAccountValue(true);
      this.isOpenModalOpenBrokerAccount = false;
    } catch (error) {
      this.$notify({
        group: 'wrn-trade',
        text: 'Não foi possível habilitar sua conta. Por favor, tente novamente.',
        type: NotificationTypes.error
      });
      console.error(error);
    }
  }

  handleAcceptRiskTerms() {
    this.canOperate = true;
  }

  private async fetchInstrumentDetailView() {
    try {
      const { about } = await getInstrumentDetailView(this.ticker);
      this.description = about.name.value;
      this.instrumentAbout = about;
    } catch (error) {
      this.instrumentAbout = {};
    }
  }

  private async fetchInstrumentStatus() {
    try {
      const { instrument } = await getInstrumentStatus(this.ticker);

      this.instrument = instrument;

      const invalidRiskProfile = this.instrument.negotiationStatus.buttons.find(
        button => button.action === 'INVALID_RISK_PROFILE'
      );

      this.canOperate = !invalidRiskProfile;
    } catch (error) {
      this.instrumentAbout = {};
    }
  }

  private setPositionsTotalEarnings(price: NumberFormatted) {
    if (!this.position || !this.position.avgPrice?.value) return;

    const totalEarnings =
      (price.value / this.position!.avgPrice!.value) * 100 - 100;
    const totalEarningsFormatted = {
      value: totalEarnings,
      formatted: percentageWithSign(totalEarnings)
    };

    // @ts-ignore
    this.position!.profitPercentage = totalEarningsFormatted;
  }

  get FeatureFlags() {
    return FeatureFlags;
  }

  private onBackClick() {
    if (
      this.$route.query.from ||
      document.referrer.includes('/suitability/profile')
    ) {
      this.$router.push({ name: 'trade' });
    } else {
      this.$router.back();
    }
  }

  onWatchlistClick() {
    trackWatchlistButtonClick(
      this.instrument!.symbol,
      this.description,
      this.instrument?.type?.formatted!
    );
  }

  private refreshInstruments() {
    // eslint-disable-next-line promise/catch-or-return
    Promise.all([this.fetchBalance(), this.fetchPositions()]);
  }
}
