import { render, staticRenderFns } from "./Costs.vue?vue&type=template&id=fd46d442&scoped=true&"
import script from "./Costs.vue?vue&type=script&setup=true&lang=ts&"
export * from "./Costs.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./Costs.vue?vue&type=style&index=0&id=fd46d442&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd46d442",
  null
  
)

export default component.exports