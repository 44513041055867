
import { Component, Prop } from 'vue-property-decorator';

import { ServicesSection } from '../ServicesSection';
import { WatchList } from '../WatchList';
import { TradeStatus } from './components/TradeStatus';
import { SidebarTypes } from '@/modules/trade/views/TradeView/components/TradeSidebar';
import { PositionsSubscribe } from './components/PositionsSubscribe';
import { EventsProvisioned } from './components/EventsProvisioned';
import { GeneralFooter } from './components/GeneralFooter';
import { HomeBrokerSection } from './components/HomeBrokerSection';
import { PositionsSummarySection } from './components/PositionsSummarySection';
import FeeTransparencyDisclaimer from '@/modules/trade/views/TradeView/components/FeeTransparencyDisclaimer/FeeTransparencyDisclaimer.vue';

import { isImpersonate } from '@/modules/common/services/partner';
import { RouteMixin } from '@/modules/common/mixins/route';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { FeatureFlags } from '@/types/models/FeatureFlags';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { IPlansProductData } from '@/modules/trade/services/store';
import {
  AccountStatus,
  Balances,
  HomeBrokerStatus
} from '@/modules/trade/services/account';
import {
  trackHomeBrokerClick,
  trackTradeMapClick,
  trackHelpClick,
  trackStatementAndNotesClick
} from '@/modules/trade/views/TradeView/trackers';
import { MFAModules } from '@/modules/authentication/views/SecurityView/components/MultiFactorAuthentication';
import { RequestStatus } from '@/modules/trade/store/trade';
import { AvailableBalance } from '@/modules/trade/components/AvailableBalance';

const tradePositionsModule = namespace('tradeModule');

@Component({
  components: {
    ServicesSection,
    WatchList,
    TradeStatus,
    PositionsSubscribe,
    GeneralFooter,
    EventsProvisioned,
    HomeBrokerSection,
    PositionsSummarySection,
    AvailableBalance,
    FeeTransparencyDisclaimer
  }
})
export default class GeneralSidebarList extends mixins(RouteMixin) {
  @tradePositionsModule.State('userHasBrokerAccount')
  readonly userHasBrokerAccount!: boolean;

  @tradePositionsModule.State('balancesBrokerRequestStatus')
  readonly balancesBrokerRequestStatus!: RequestStatus;

  @tradePositionsModule.Action('fetchBalances')
  readonly fetchBalances!: Function;

  @Prop({ type: Object, required: false })
  readonly balances?: Balances;

  @Prop({ type: Object, required: false })
  readonly tradeStatus?: AccountStatus;

  @Prop({ type: Function, default: () => {} })
  readonly onAccessHomeBrokerClick!: Function;

  @Prop({ type: Function, required: true })
  readonly setPanel!: Function;

  @Prop({ type: Function, required: true })
  readonly getWatchlistSubscription!: () => {};

  @Prop({ type: Function, default: () => {} })
  readonly openBrokerModal!: Function;

  @Prop({ type: Function, default: () => {} })
  readonly openTradeMapModal!: Function;

  @Prop({ type: Object, default: null })
  readonly homeBrokerStatus!: HomeBrokerStatus | null;

  @Prop({ type: Array, required: true })
  readonly customerProducts!: IPlansProductData[];

  private isImpersonate = isImpersonate();

  private get homeBrokerButtonEnable() {
    if (this.isImpersonate || this.updatedAccountRegulation) {
      return false;
    }

    return true;
  }

  get unifiedAccountAvailableBalance() {
    return {
      availableOnAccountValue: this.balances?.availableOnAccountValue,
      availableToOperateValue: this.balances?.availableToOperateValue,
      toSettleValue: this.balances?.toSettleValue
    };
  }

  get hasBalancesBrokerRequestFailed() {
    return this.balancesBrokerRequestStatus === RequestStatus.Error;
  }

  get isBalancesBrokerRequestLoading() {
    return this.balancesBrokerRequestStatus === RequestStatus.Loading;
  }

  get isMarketNotAvailable() {
    return !this.tradeStatus?.marketAvailable?.isMarketAvailable;
  }

  get statusMarketAvailable() {
    return this.tradeStatus?.marketAvailable;
  }

  get tradeMapTitle() {
    return this.hasTradeMapCustomer ? 'TradeMap Ativo' : 'Ativar TradeMap';
  }

  private get hasTradeMapCustomer() {
    return this.customerProducts.some(product => product.code === 'trademap');
  }

  get isSubscribesFeatureEnabled() {
    return FeatureFlagsModule.isEnabled(
      FeatureFlags.TRADE_ALLOCATION_SUBSCRIBES
    );
  }

  get isProvisionedEventsFeatureEnabled() {
    return FeatureFlagsModule.isEnabled(FeatureFlags.TRADE_EVENTS_PROVISIONED);
  }

  /**
   * This is temporally, this panel transition will be change for a new all positions page
   */
  public setSubscriptionsPanel(setPanel: (param: SidebarTypes) => void) {
    setPanel(SidebarTypes.SubscriptionsList);
    window.scrollTo(0, 0);
  }

  /**
   * This is temporally, this panel transition will be change for a new all watchlist page
   */
  public setWatchlistPanel(setPanel: (param: SidebarTypes) => void) {
    setPanel(SidebarTypes.Watchlist);
    window.scrollTo(0, 0);
  }

  private requestTradeMapAction() {
    trackTradeMapClick();
    this.openTradeMapModal();
  }

  public setEventsProvisionedPanel(setPanel: (param: SidebarTypes) => void) {
    setPanel(SidebarTypes.EventsProvisionedList);
    window.scrollTo(0, 0);
  }

  public requestBrokerAction() {
    if (this.homeBrokerStatus?.status === 'Enabled') {
      if (this.homeBrokerButtonEnable) {
        this.onAccessHomeBrokerClick();
      }
    } else {
      trackHomeBrokerClick('Solicitar Home Broker');
      this.openBrokerModal();
    }
  }

  public goToSecurity() {
    trackHomeBrokerClick(
      'Ativar Home Broker - Cadastre a verificação em duas etapas'
    );
    this.$router.push({
      name: 'security',
      query: { module: MFAModules.TRADE }
    });
  }

  handleHelpClick() {
    trackHelpClick();
  }

  public trackAccountStatements() {
    trackStatementAndNotesClick();
  }

  get FeatureFlags() {
    return FeatureFlags;
  }

  private get updatedAccountRegulation() {
    return this.tradeStatus && !this.tradeStatus.enabledBuy;
  }

  private setSummaryPanels(panel: SidebarTypes) {
    this.setPanel(panel);
    window.scrollTo(0, 0);
  }
}
