
import { Component, Vue, Inject } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TradeSidebar } from './components/TradeSidebar';
import { TradeMain } from './components/TradeMain';
import {
  ActionableHelperView,
  ContextColorProvider
} from '@warrenbrasil/nebraska-web';

import {
  TwoColumnsContainer,
  TwoColumnsContainerLoader
} from '@/modules/common/components/TwoColumnsContainer';
import {
  postHomeBrokerToken,
  getHomeBrokerUrl
} from '@/modules/trade/services/home-broker';
import { Notification } from '@/modules/common/components/Notification';
import {
  checkUserBrokerAccount,
  enableUserBrokerAccount
} from '@/modules/trade/services/unified-account';
import { FirstAccessUnifiedAccountModal } from '@/modules/trade/components/FirstAccessUnifiedAccountModal';
import {
  IDashButtons,
  Balances,
  AccountStatus,
  HomeBrokerStatus,
  IDashStatusCard
} from '@/modules/trade/services/account';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import { RequestBrokerModal } from './components/RequestBrokerModal';
import { RequestTradeMapModal } from './components/RequestTradeMapModal';
import { BlockedBalanceModal } from './components/BlockedBalanceModal';
import {
  getProductByCustomer,
  IPlansProductData
} from '@/modules/trade/services/store';
import { ErrorMessages, BrokerType, BrokerButtonLabel } from './types';

import {
  trackTradeViewLoad,
  trackHomeBrokerClick,
  trackDashStatusButtonClick
} from './trackers';
import { logError } from '@/modules/trade/helpers/logs';

import { FeatureFlags } from '@/types/models/FeatureFlags';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { RegulatoryUpdateWarning } from '@/modules/common/components/RegulatoryUpdateWarning';
import { WarrenModules } from '@/types/navigation';
const tradePositionsModule = namespace('tradeModule');
const customerModule = namespace('CustomerModule');

@Component({
  components: {
    FirstAccessUnifiedAccountModal,
    ContextColorProvider,
    TradeSidebar,
    TradeMain,
    TwoColumnsContainer,
    TwoColumnsContainerLoader,
    ActionableHelperView,
    RequestBrokerModal,
    RequestTradeMapModal,
    BlockedBalanceModal,
    Notification,
    RegulatoryUpdateWarning
  }
})
export default class TradeView extends Vue {
  private loading = true;
  private error = false;
  private errorHomeBroker = false;
  private errorType = 'generic';
  private errorButton = 'Tentar novamente';
  private showHomeBrokerModal = false;
  private showTradeMapModal = false;
  private showBlockedBalanceModal = false;
  private customerProducts: IPlansProductData[] = [];
  private homeBrokeraction = '';

  isOpenFirstAccessUnifiedAccountModal = false;

  @tradePositionsModule.Action('resetTradeHomeStates')
  private resetTradeHomeStates!: Function;

  @tradePositionsModule.Action('fetchBalances')
  private fetchBalances!: Function;

  @tradePositionsModule.Action('fetchDashStatus')
  private fetchDashStatus!: Function;

  @tradePositionsModule.Action('changeUserHasBrokerAccountValue')
  private changeUserHasBrokerAccountValue!: Function;

  @tradePositionsModule.State('balances')
  readonly balances!: Balances;

  @tradePositionsModule.State('dashStatusCard')
  readonly dashStatusCard!: IDashStatusCard | null;

  @tradePositionsModule.State('accountButtons')
  readonly accountButtons!: IDashButtons[];

  @customerModule.Getter('getCustomerId') private customer!: string;

  @tradePositionsModule.State('tradeStatus')
  readonly tradeStatus!: AccountStatus;

  @tradePositionsModule.State('homeBrokerStatus')
  readonly homeBrokerStatus!: HomeBrokerStatus | null;

  @tradePositionsModule.Action('fetchStatus')
  private fetchStatus!: Function;

  public readonly WarrenModules = WarrenModules;

  get isTradeNewBrokerFeatureFlagEnabled() {
    return FeatureFlagsModule.isEnabled(
      FeatureFlags.TRADE_ENABLE_NEW_BROKER_CLIENT_FLOW
    );
  }

  mounted() {
    this.load();
  }

  destroyed() {
    this.resetTradeHomeStates();
  }

  @Inject()
  reloadCurrentRoute!: Function;

  private async load() {
    this.loading = true;
    this.error = false;
    await Promise.all([
      this.fetchDashStatus(),
      this.fetchStatus(),
      this.fetchProductsByCutomer(),
      this.fetchBalances(),
      this.fetchFirstAccessUnifiedAccount(),
      this.fetchHomeBrokerUrl()
    ]);
    trackTradeViewLoad();
    this.loading = false;
  }

  openTradeMapModal() {
    this.showTradeMapModal = true;
  }

  closeTradeMapModal() {
    this.showTradeMapModal = false;
  }

  openBrokerModal() {
    this.showHomeBrokerModal = true;
  }

  closeBrokerModal() {
    this.showHomeBrokerModal = false;
  }

  handleBlockedBalanceModal() {
    this.showBlockedBalanceModal = !this.showBlockedBalanceModal;
  }

  handleFirstAccessUnifiedAccountModal() {
    this.isOpenFirstAccessUnifiedAccountModal =
      !this.isOpenFirstAccessUnifiedAccountModal;
  }

  async enableMarketClient() {
    try {
      this.handleFirstAccessUnifiedAccountModal();
      await enableUserBrokerAccount();
      this.$notify({
        group: 'wrn-trade',
        text: 'Conta habilitada com sucesso.',
        type: NotificationTypes.success
      });
      this.changeUserHasBrokerAccountValue(true);
      this.reloadCurrentRoute();
    } catch (error) {
      this.$notify({
        group: 'wrn-trade',
        text: 'Não foi possível habilitar sua conta. Por favor, tente novamente.',
        type: NotificationTypes.error
      });
    }
  }

  private updateTradeMapStatus() {
    this.fetchProductsByCutomer();
    this.showTradeMapModal = false;
  }

  private updateHomeBrokerStatus() {
    this.fetchStatus();
    this.showHomeBrokerModal = false;
  }

  private async fetchProductsByCutomer() {
    try {
      const { products } = await getProductByCustomer();
      this.customerProducts = products;
    } catch (error) {
      this.customerProducts = [];
    }
  }

  private async fetchFirstAccessUnifiedAccount() {
    try {
      if (this.isTradeNewBrokerFeatureFlagEnabled) {
        await this.chooseModalToShow();
      }
    } catch (error) {
      logError(error, 'request_has_broker_account_home_error');
      this.error = true;
      console.error(error);
    }
  }

  private async fetchHomeBrokerUrl() {
    try {
      const url = await getHomeBrokerUrl();
      this.homeBrokeraction = url;
    } catch (_) {
      this.homeBrokeraction = String(
        process.env.VUE_APP_HOME_BROKER_NELOGICA_URL
      );
    }
  }

  private async chooseModalToShow() {
    const userHasBrokerAccount = await checkUserBrokerAccount();

    this.isOpenFirstAccessUnifiedAccountModal = userHasBrokerAccount;
    this.changeUserHasBrokerAccountValue(userHasBrokerAccount);
  }

  private createHomeBrokerForm() {
    const action = this.homeBrokeraction;
    const form = document.createElement('form');
    const input = document.createElement('input');

    form.appendChild(input);

    form.action = action;
    form.method = 'POST';

    input.type = 'hidden';
    input.name = 'token';

    return { form, input };
  }

  /**
   * Creates a form that holds the home broker integration.
   *
   * Returns a tuple, containing { form, input }:
   *
   * - form: HTMLFormElement, used to submit data
   * - input: HTMLInputElement that contains the token used on the login integration
   */

  private fetchHomeBroker() {
    trackHomeBrokerClick('Acessar Home Broker');
    postHomeBrokerToken(this.customer)
      // eslint-disable-next-line promise/always-return
      .then(({ token }) => {
        const { form, input } = this.createHomeBrokerForm();
        const homeBrokerWindow = globalThis.open() as Window;
        const target = homeBrokerWindow.document.body;
        input.value = token;

        target.appendChild(form);
        form.submit();
      })
      .catch(error => {
        const errorMessage = error.message;
        this.errorHomeBroker = true;
        if (
          errorMessage === ErrorMessages.invalidId ||
          errorMessage === ErrorMessages.invalidRequest
        ) {
          this.errorType = BrokerType.instability;
          this.errorButton = BrokerButtonLabel.returnToHome;
        }
      });
  }

  private onBrokerError() {
    if (this.errorType === BrokerType.generic) {
      this.fetchHomeBroker();
    } else {
      this.errorHomeBroker = false;
    }
  }

  private handleDashButtonsEvents(dashStatusAction?: string | null) {
    return trackDashStatusButtonClick(dashStatusAction);
  }
}
