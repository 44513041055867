import { RouteConfig, Route } from 'vue-router';

import WealthView from '@/modules/wealth/views/Wealth/WealthView.vue';
import WealthPortfolioWithdraw from '@/modules/wealth/views/WealthPortfolioWithdraw/WealthPortfolioWithdraw.vue';
import WealthPortfolioWithdrawReview from '@/modules/wealth/views/WealthPortfolioWithdrawReview/WealthPortfolioWithdrawReview.vue';
import WealthPortfolioWithdrawCompleted from '@/modules/wealth/views/WealthPortfolioWithdrawCompleted/WealthPortfolioWithdrawCompleted.vue';
import WealthProductTransaction from '@/modules/wealth/views/WealthProductTransaction/WealthProductTransaction.vue';
import WealthProductWithdraw from '@/modules/wealth/views/WealthProductWithdraw/WealthProductWithdraw.vue';
import WealthProductWithdrawReview from '@/modules/wealth/views/WealthProductWithdrawReview/WealthProductWithdrawReview.vue';
import WealthProductWithdrawCompleted from '@/modules/wealth/views/WealthProductWithdrawCompleted/WealthProductWithdrawCompleted.vue';
import InvestmentCompleted from '@/modules/wealth/views/InvestmentCompleted/InvestmentCompleted.vue';
import InvestmentSuggestionsView from '@/modules/wealth/views/InvestmentSuggestions/InvestmentSuggestionsView.vue';
import IndexPortfolio from '@/modules/wealth/views/IndexPortfolio/IndexPortfolio.vue';
import WealthPerformance from '@/modules/wealth/views/WealthPerformance/WealthPerformance.vue';
import PortfolioStatementsAndBrokerageNotes from '@/modules/wealth/views/PortfolioStatementsAndBrokerageNotes/PortfolioStatementsAndBrokerageNotes.vue';
import WealthPerformanceV2 from '@/modules/wealth/views/WealthPerformanceV2/WealthPerformanceV2.vue';
import WealthPortfolioV2 from '@/modules/wealth/views/WealthPortfolioV2/WealthPortfolioV2.vue';

import WealthBuilderView from '@/modules/wallet-builder/src/views/Builder.vue';
import WealthCreatedView from '@/modules/wallet-builder/src/views/Created.vue';
import WealthEditedView from '@/modules/wallet-builder/src/views/Edited.vue';
import WealthWithdrawSelectionView from '@/modules/wealth/views/WealthWithdrawSelection/WealthWithdrawSelectionView.vue';
import WealthOrdersView from '@/modules/wealth/views/WealthOrders/WealthOrdersView.vue';
import IndexPortfolioProfit from '@/modules/wealth/views/IndexPortfolioProfit/IndexPortfolioProfit.vue';

import { beforeEnterInvestment } from '@/modules/common/routes/navigation-guards/investment';

export const wealthRoutes: RouteConfig[] = [
  {
    name: 'wealth',
    path: '/wealth',
    component: WealthView,
    meta: {
      isHomePage: true,
      context: 'wealth'
    }
  },
  {
    name: 'wealthIndexPortfolio',
    path: '/wealth/index-portfolio/:portfolioId',
    component: IndexPortfolio,
    meta: {
      context: 'wealth'
    }
  },
  {
    name: 'wealthPortfolio',
    path: '/wealth/portfolio/:id',
    component: WealthPortfolioV2,
    meta: {
      context: 'wealth'
    }
  },
  {
    name: 'wealthPortfolioStatementsAndBrokerageNotes',
    path: '/wealth/statements-and-brokerage-notes/:portfolioId',
    component: PortfolioStatementsAndBrokerageNotes,
    meta: {
      context: 'wealth'
    }
  },
  {
    name: 'wealthPerformanceV2',
    path: '/wealth/performance/:portfolioId',
    component: WealthPerformanceV2,
    meta: {
      context: 'wealth'
    }
  },
  {
    name: 'wealthBuilderView',
    path: '/wealth/builder',
    component: WealthBuilderView,
    meta: {
      context: 'wealth'
    },
    props: ({ query }: Route) => ({
      instrumentId: query.instrumentId || '',
      customerId: query.customerId || '',
      isPartner: !!query.isPartner,
      isLead: !!query.isLead,
      isTemplate: !!query.isTemplate
    })
  },
  {
    name: 'wealthBuilderViewEdit',
    path: '/wealth/builder/:portfolioId',
    component: WealthBuilderView,
    meta: {
      context: 'wealth'
    },
    props: ({ query, params }: Route) => ({
      instrumentId: query.instrumentId || '',
      portfolioId: params.portfolioId || ''
    })
  },
  {
    name: 'wealthProductTransactionCompleted',
    path: '/wealth/investment-completed',
    component: InvestmentCompleted,
    meta: {
      context: 'wealth'
    }
  },
  {
    name: 'wealthPerformance',
    path: '/wealth-performance',
    component: WealthPerformance,
    meta: {
      context: 'wealth'
    }
  },
  {
    name: 'wealthWalletCreated',
    path: '/wealth/builder/:id/created',
    component: WealthCreatedView,
    meta: {
      context: 'wealth'
    }
  },
  {
    name: 'wealthWalletEdited',
    path: '/wealth/builder/:id/edited',
    component: WealthEditedView,
    meta: {
      context: 'wealth'
    }
  },
  {
    name: 'wealthProductTransaction',
    path: '/wealth/:id/investment-by-product',
    beforeEnter: beforeEnterInvestment,
    component: WealthProductTransaction,
    meta: {
      context: 'wealth'
    }
  },
  {
    name: 'wealthProductWithdrawal',
    path: '/wealth/:id/withdraw-by-product',
    component: WealthProductWithdraw,
    meta: {
      context: 'wealth'
    },
    props: route => {
      const { params } = route;
      return { portfolioId: params.id };
    }
  },
  {
    name: 'wealthProductWithdrawalReview',
    path: '/wealth/:id/withdraw-by-product-review',
    component: WealthProductWithdrawReview,
    meta: {
      context: 'wealth'
    },
    props: route => {
      const { params } = route;
      return { portfolioId: params.id };
    }
  },
  {
    name: 'wealthProductWithdrawalSuccess',
    path: '/wealth/:id/withdraw-by-product-completed',
    component: WealthProductWithdrawCompleted,
    meta: {
      context: 'wealth'
    },
    props: route => {
      const { params } = route;
      return { portfolioId: params.id };
    }
  },
  {
    name: 'wealthWithdrawSelection',
    path: '/wealth-withdraw-selection',
    component: WealthWithdrawSelectionView,
    meta: {
      context: 'wealth'
    }
  },
  {
    name: 'wealthOrders',
    path: '/wealth-orders',
    component: WealthOrdersView,
    meta: {
      context: 'wealth'
    }
  },
  {
    name: 'investmentSuggestions',
    path: '/investment-suggestions',
    component: InvestmentSuggestionsView,
    meta: {
      context: 'wealth'
    }
  },
  {
    name: 'wealthPortfolioWithdrawal',
    path: '/wealth/:id/withdraw-by-portfolio',
    component: WealthPortfolioWithdraw,
    meta: {
      context: 'wealth'
    },
    props: route => {
      const { params } = route;
      return { portfolioId: params.id };
    }
  },
  {
    name: 'wealthPortfolioWithdrawReview',
    path: '/wealth/:id/withdraw-by-portfolio-review',
    component: WealthPortfolioWithdrawReview,
    meta: {
      context: 'wealth'
    },
    props: ({ params }: Route) => ({
      portfolioId: params.id
    })
  },
  {
    name: 'wealthPortfolioWithdrawCompleted',
    path: '/wealth/:id/withdraw-by-portfolio-completed',
    component: WealthPortfolioWithdrawCompleted,
    meta: {
      context: 'wealth'
    },
    props: ({ params }: Route) => ({
      portfolioId: params.id
    })
  },
  {
    name: 'wealthIndexPortfolioProfitDetail',
    path: '/wealth/:portfolioId/profit-detail/:year/:month',
    component: IndexPortfolioProfit,
    meta: {
      context: 'wealth'
    },
    props: ({ params }: Route) => ({
      portfolioId: params.id,
      year: params.year,
      month: params.month
    })
  }
];
