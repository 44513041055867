import { Route, RouteConfig } from 'vue-router';

import ProductsContainer from '@/modules/products/views/ProductsContainer/ProductsContainer.vue';
import ProductsDetailsContainer from '@/modules/products/views/ProductsDetailsContainer/ProductsDetailsContainer.vue';
import GlobalAllocation from '@/modules/products/views/GlobalAllocation/GlobalAllocationView.vue';
import ProductsView from '@/modules/products/views/ProductsView/ProductsView.vue';
import { Products as ProductsCatalog } from '@/modules/catalog/views/Products';
import { productsViewGuard } from './navigation-guards/products-view';

export const productsRoutes: RouteConfig[] = [
  {
    name: 'productsView',
    path: '/products-view',
    beforeEnter: productsViewGuard,
    component: ProductsView,
    meta: {
      isHomePage: true,
      context: 'products'
    }
  },
  {
    name: 'productsCatalog',
    path: '/products-view/catalog',
    component: ProductsCatalog,
    meta: {
      context: 'products'
    },
    props: () => ({
      isBuilder: false
    })
  },
  {
    name: 'productsCatalog',
    path: '/products-view/catalog/:productId',
    component: ProductsCatalog,
    meta: {
      context: 'products'
    },
    props: () => ({
      isBuilder: false
    })
  },
  {
    name: 'products',
    path: '/products',
    meta: { requiresAuth: false },
    component: ProductsContainer,
    props: ({ query }: Route) => ({
      instrumentId: query.instrumentId || ''
    })
  },
  {
    name: 'productsAllocation',
    path: '/products/allocation',
    alias: '/overview/products-allocation',
    meta: { scroll: false },
    component: GlobalAllocation
  },
  {
    name: 'product',
    path: '/product/:apiId',
    meta: { requiresAuth: false },
    redirect: { name: 'products-details' }
  },
  {
    name: 'products-details',
    path: '/products/:apiId',
    meta: { requiresAuth: false },
    component: ProductsDetailsContainer,
    props: ({ params }: Route) => ({
      apiId: params.apiId || ''
    })
  }
];
