
import { Vue, Prop, Emit, Component, Watch } from 'vue-property-decorator';
import { Overlay } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  toggleBodyScroll,
  addBodyScroll
} from '@/modules/common/helpers/browser';

type Position = 'top' | 'right' | 'bottom' | 'left';
type Container = 'screen-size' | '';
type ContentSize = 'md' | '';

interface ScrollTarget {
  target: {
    scrollTop: number;
    clientHeight: number;
    scrollHeight: number;
  };
}

@Component({
  components: {
    Overlay
  }
})
export default class SideSheet extends Vue {
  @Prop({ type: Boolean, default: true })
  public hasOverlay!: boolean;

  @Prop({ type: Boolean, default: false })
  public isOpen!: boolean;

  @Prop({ type: String, default: 'top' })
  public position!: Position;

  @Prop({ type: String, default: '' })
  public containerClass!: Container;

  @Prop({ type: String, default: '' })
  public contentSize!: ContentSize;

  @Prop({ type: String, default: '' })
  public contentColor!: string;

  @Prop({ type: Boolean, default: false })
  public hasContentBackground?: boolean;

  private NebraskaColors = NebraskaColors;

  @Watch('isOpen')
  private toggleScroll() {
    toggleBodyScroll();
  }

  @Emit()
  private close() {
    return false;
  }

  private handleScroll({
    target: { scrollTop, clientHeight, scrollHeight }
  }: ScrollTarget) {
    if (scrollTop + clientHeight >= scrollHeight) {
      this.$emit('bottom');
    }
  }

  private get computedClass(): string[] {
    const classes = [];

    classes.push(this.position);
    classes.push(this.contentSize);

    return classes;
  }

  private get computedClassContainer(): string[] {
    const classes = [];

    classes.push(this.containerClass);

    return classes;
  }

  destroyed() {
    addBodyScroll();
  }
}
